import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { NavLink, BrowserRouter as Router } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  Grid,
  Button,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  Stack,
  IconButton,
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import { DrawerList } from "./components/DrawerList";
import ComponentRouter from "./Routes";
import appLogo from "./components/image/logo/appLogo.png";
import MWSelectLang, { MobileSelectLang } from "./components/MWSelectLang";
import LogoutConfirmationDialog from "./pages/LogoutConfirmationDialog";

const drawerWidth = 240;
interface Props {
  windows?: () => Window;
}

export default function App(props: Props) {
  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const Bearer = localStorage.getItem("access");

  let usernameCheck: string;

  const userFirstName = function () {
    return localStorage.getItem("firstname");
  };

  let firstName: string = userFirstName()!;

  const userLastName = function () {
    return localStorage.getItem("lastname");
  };

  let lastName: string = userLastName()!;

  if (firstName === "") {
    usernameCheck = "Unknown";
  } else {
    usernameCheck = firstName + " " + lastName;
  }

  const userEmailCheck = function () {
    return localStorage.getItem("email");
  };
  let userEmail: string = userEmailCheck()?.toLowerCase()!;

  const [logoutDialogState, setDialogState] = useState(false);
  const handleLogout = () => {
    setDialogState(true);
  };
  const dialogClose = () => {
    setDialogState(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const drawer = (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      py={1}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          width: "100%",
        }}
      >
        <Toolbar />
        <List
          style={{
            width: "100%",
          }}
          dense
        >
          {DrawerList.slice(0, 1).map((item) => {
            return (
              <ListItem
                component={NavLink}
                activeClassName="Mui-selected"
                to={item.path}
                key={item.name}
                disablePadding
              >
                <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={t(`menu.${item.name}`, { ns: ["home"] })!}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
          <Divider sx={{ bgcolor: "#6faeff" }} />
          {DrawerList.slice(1, 6).map((item) => {
            return (
              <ListItem
                component={NavLink}
                activeClassName="Mui-selected"
                to={item.path}
                key={item.name}
                disablePadding
              >
                <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={t(`menu.${item.name}`, { ns: ["home"] })!}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Stack>
      <Box
        style={{
          textDecoration: "none",
          width: "100%",
        }}
      >
        <Divider sx={{ bgcolor: "#6faeff" }} />
        <List
          dense
          style={{
            width: "100%",
          }}
        >
          {DrawerList.slice(-2, -1).map((item) => {
            return (
              <ListItem
                component={NavLink}
                activeClassName="Mui-selected"
                to={item.path}
                key={item.name}
                disablePadding
              >
                <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={t(`menu.${item.name}`, { ns: ["home"] })!}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
          {DrawerList.slice(-1).map((item) => {
            return (
              <ListItem key={item.name} disablePadding>
                <ListItemButton
                  onClick={() => handleLogout()}
                  sx={{ py: 0, minHeight: 32 }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={t(`menu.${item.name}`, { ns: ["home"] })!}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Stack>
  );
  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <Router>
        {Bearer ? (
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#ffffff",
            }}
          >
            <Toolbar>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                px={1}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { sm: "none" } }}
                      >
                        <MenuIcon sx={{ color: "#757575" }} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Link href="/" to={""}>
                        <Box
                          component="img"
                          sx={{
                            height: 40,
                          }}
                          alt="medicalWISDOM logo"
                          src={appLogo}
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                            xl: "flex",
                          },
                        }}
                      >
                        <MWSelectLang isMenu />
                      </Box>
                      <Box
                        sx={{
                          display: {
                            xs: "flex",
                            sm: "flex",
                            md: "flex",
                            lg: "none",
                            xl: "none",
                          },
                        }}
                      >
                        <MobileSelectLang />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <Button
                          size="small"
                          aria-describedby={id}
                          onClick={handleClick}
                          sx={{ marginTop: 0.5 }}
                        >
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={0.5}
                          >
                            <Grid item>
                              <Avatar />
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                              >
                                <Grid item>
                                  <Typography
                                    sx={{ textTransform: "capitalize" }}
                                    variant="subtitle2"
                                    noWrap
                                  >
                                    <b>{usernameCheck}</b>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    noWrap
                                    variant="caption"
                                    style={{
                                      textTransform: "lowercase",
                                    }}
                                  >
                                    {userEmail}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton
                          size="large"
                          aria-label="show more"
                          aria-haspopup="true"
                          onClick={handleClick}
                          sx={{ color: "#757575" }}
                        >
                          <MoreIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <List dense>
                  <ListItem>
                    <Box sx={{ display: { xs: "block", md: "none" } }}>
                      <ListItemText
                        primary={usernameCheck}
                        secondary={userEmail}
                      />
                    </Box>
                  </ListItem>
                  <Divider sx={{ display: { xs: "block", md: "none" } }} />
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/userprofile"
                      style={{
                        textDecoration: "none",
                      }}
                      onClick={() => handleClose()}
                    >
                      <ListItemText>
                        {t("menu.profile", { ns: ["home"] })}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </Toolbar>
          </AppBar>
        ) : null}

        {Bearer ? (
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        ) : null}
        <Box component="main" sx={{ flexGrow: 1 }}>
          {Bearer ? <Toolbar /> : null}
          {Bearer ? (
            <>
              <ComponentRouter />
            </>
          ) : (
            <ComponentRouter />
          )}
        </Box>
      </Router>
      <LogoutConfirmationDialog
        dialogState={logoutDialogState}
        dialogChildState={dialogClose}
      />
    </Box>
  );
}
