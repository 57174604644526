import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "./MWDialogTransition";
import CloseIcon from "@mui/icons-material/Close";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";
import { useTranslation } from "react-i18next";

const Controls = ({
  zoomIn,
  zoomOut,
  resetTransform,
}: ReactZoomPanPinchContentRef) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 2,
      transform: "translate(10px, 10px)",
      maxWidth: "calc(100% - 20px)",
    }}
  >
    <Button onClick={() => zoomIn()}>+ Zoom in</Button>
    <Button onClick={() => zoomOut()}>- Zoom out</Button>
    <Button onClick={() => resetTransform()}>x Reset</Button>
  </Box>
);

export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: any;
  selectedItemUpdateState: any;
  finalOpinion?: boolean | any;
  questionnaire?: boolean | any;
  questionnaireSecName?: string | any;
}
export default function DocumentViewer({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
  finalOpinion,
  questionnaire,
  questionnaireSecName,
}: Props) {
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] =
    useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    selectedItemUpdateState({} as any);
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">
              {selectedItem?.description !== undefined
                ? selectedItem?.description !==
                  `${t("common.notSet", { ns: ["home"] })}`
                  ? `${t("filePreparationTab.viewDocumentOf", {
                      ns: ["home"],
                    })}` +
                    " " +
                    selectedItem?.description
                  : finalOpinion
                  ? selectedItem?.description
                  : questionnaire
                  ? `${t("filePreparationTab.viewDocumentOf", {
                      ns: ["home"],
                    })}` +
                    " " +
                    selectedItem?.description
                  : null
                : null}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TransformWrapper
          initialScale={1}
          initialPositionX={50}
          initialPositionY={50}
        >
          {(utils) => (
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Controls {...utils} />
              <TransformComponent
                wrapperStyle={{
                  width: "100%",
                  height: "100vw",
                  maxWidth: "100%",
                  maxHeight: "calc(100vh - 50px)",
                }}
              >
                {selectedItem?.supportingDocument !== null ? (
                  selectedItem?.supportingDocument !== "" ? (
                    selectedItem?.supportingDocument?.includes("mp4") ||
                    selectedItem?.supportingDocument?.includes("pdf") ||
                    selectedItem?.supportingDocument?.includes("quicktime") ||
                    selectedItem?.supportingDocument?.includes("webm") ||
                    selectedItem?.supportingDocument?.includes("3gpp") ||
                    selectedItem?.supportingDocument?.includes("x-flv") ||
                    selectedItem?.supportingDocument?.includes("x-ms-asf") ||
                    selectedItem?.supportingDocument?.includes("mp3") ? (
                      <iframe
                        src={selectedItem?.supportingDocument}
                        title="Small document"
                        width="800"
                        height="700"
                        allow="fullscreen"
                      />
                    ) : (
                      <img
                        style={{
                          maxInlineSize: "100%",
                          blockSize: "auto",
                        }}
                        src={selectedItem?.supportingDocument}
                        alt="test"
                      />
                    )
                  ) : null
                ) : null}
              </TransformComponent>
            </Stack>
          )}
        </TransformWrapper>
      </DialogContent>
    </Dialog>
  );
}
