import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Select,
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";
import Flagde from "../components/image/de.png";
import Flagen from "../components/image/en.png";
import Flagfr from "../components/image/fr.png";
import Flagit from "../components/image/it.png";

interface Props {
  isMenu?: boolean;
}
const MWSelectLang: React.FC<Props> = ({ isMenu = false }) => {
  const { t, i18n } = useTranslation(["home"]);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("lng") || "de"
  );
  const onClickLanguageChange = (e: any) => {
    const language = e.target.value;
    localStorage.setItem("lng", language);
    setSelectedLang(language);
    i18n.changeLanguage(language); //change the language
    window.location.reload();
  };
  return (
    <FormControl sx={{ m: 1, minHeight: 1 }} size="small">
      <Select value={selectedLang} onChange={onClickLanguageChange}>
        <MenuItem value="de">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img alt="" src={Flagde} height="25px" width="25px" />
            </Grid>
            <Grid item>Deutsch</Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="en">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img alt="" src={Flagen} height="25px" width="25px" />
            </Grid>
            <Grid item>English</Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="fr">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img alt="" src={Flagfr} height="25px" width="25px" />
            </Grid>
            <Grid item>French</Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="it">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img alt="" src={Flagit} height="25px" width="25px" />
            </Grid>
            <Grid item>Italiano</Grid>
          </Grid>
        </MenuItem>
      </Select>
      {!isMenu && (
        <FormHelperText variant="standard" sx={{ fontSize: 12 }}>
          {t("common.selectLang", { ns: ["home"] })}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MWSelectLang;
export function MobileSelectLang() {
  const { i18n } = useTranslation(["home"]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  // const [selectedLang, setSelectedLang] = useState(localStorage.getItem("lng") || "de");
  console.log("localStorage", localStorage.getItem("lng"));
  const onClickLanguageChange = (passedVal: string) => {
    localStorage.setItem("lng", passedVal);
    i18n.changeLanguage(passedVal); //change the language
    window.location.reload();
  };
  useEffect(() => {
    localStorage.getItem("lng") !== null
      ? localStorage.getItem("lng") === "de"
        ? setSelectedIndex(0)
        : localStorage.getItem("lng") === "en"
        ? setSelectedIndex(1)
        : localStorage.getItem("lng") === "fr"
        ? setSelectedIndex(2)
        : localStorage.getItem("lng") === "it"
        ? setSelectedIndex(3)
        : setSelectedIndex(0)
      : setSelectedIndex(0);
  }, []);
  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleClick}
        sx={{ display: { sm: "none" } }}
      >
        <TranslateIcon sx={{ color: "#757575" }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List dense>
          <ListItem disablePadding>
            <ListItemButton
              style={{
                textDecoration: "none",
              }}
              selected={selectedIndex === 0}
              onClick={(event) => {
                handleClose();
                onClickLanguageChange("de");
                handleListItemClick(event, 0);
              }}
            >
              <ListItemText>Deutsch</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              style={{
                textDecoration: "none",
              }}
              selected={selectedIndex === 1}
              onClick={(event) => {
                handleClose();
                onClickLanguageChange("de");
                handleListItemClick(event, 1);
              }}
            >
              <ListItemText>English</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              style={{
                textDecoration: "none",
              }}
              selected={selectedIndex === 2}
              onClick={(event) => {
                handleClose();
                onClickLanguageChange("fr");
                handleListItemClick(event, 2);
              }}
            >
              <ListItemText>French</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              style={{
                textDecoration: "none",
              }}
              selected={selectedIndex === 3}
              onClick={(event) => {
                handleClose();
                onClickLanguageChange("it");
                handleListItemClick(event, 3);
              }}
            >
              <ListItemText>Italiano</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
