import { Alert, AlertColor, Card, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { OrderId } from "../../../../redux/payment/PaymentApprove/Model";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentApproveValue } from "../../../../redux/payment/PaymentApprove/API";
import { AppState } from "../../../../redux/store/Store";
import MWLoader from "../../../../components/MWLoader";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PayPal() {
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const orderid = query.get("token");
  const patientid = query.get("patientid");
  const patientcaseid = query.get("patientcaseid");
  const paymentOrderId = { orderid } as OrderId;

  const paymentApproveValue = useSelector(
    (state: AppState) => state.getPaymentApproveValue
  );

  const [paymentValue, setPaymentValue] = useState("");
  const [paymentValueMsgType, setPaymentValueMsgType] =
    useState<AlertColor>("success");
  const [counter, setCounter] = useState(10);
  const [alert, setAlert] = useState(true);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(Number(timer));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
      history.push(`/casedetails/${patientid}/${patientcaseid}?&tab=payment`);
    }, 11000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentApproveValue?.paymentApproveResponse?.message !== undefined) {
      setPaymentValue(paymentApproveValue?.paymentApproveResponse?.message);
      setPaymentValueMsgType("success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentApproveValue]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPaymentApproveValue(paymentOrderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box>
      {paymentApproveValue.loading ? <MWLoader /> : null}
      <Container maxWidth="xl">
        <Typography variant="h5" sx={{ mt: 2 }}>
          Payment Confirmation
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Card>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              spacing={1}
            >
              <Grid item>{alert && <Alert severity={paymentValueMsgType}>{paymentValue}</Alert>}</Grid>
              <Grid item m={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item mr={1}><Typography variant="h6">You are being redirected to another page. Please don't refresh or close this window.</Typography></Grid>
                  <Grid item><Typography variant="h6" sx={{ color: "#208781" }}>{counter}</Typography></Grid>
                  <Grid item ml={1}><Typography variant="h6">seconds</Typography></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}
