import axios from "axios";
import { Dispatch } from "redux";
import { GetDoctorListByNameActionTypes } from "./ActionTypes";
import { GetDoctorListByNameRes, getDoctorListByNameBody } from "./Model";
import {
  getDoctorListByNameAPIResClearAction,
  getDoctorListByNameErrorAction,
  getDoctorListByNameLoadingAction,
  getDoctorListByNameSuccessAction,
  getDoctorListByNameAPIMsgAction,
} from "./Actions";
import { headers } from "../../../components/Utility";

let apiRes = {} as GetDoctorListByNameRes | any;
export const getDoctorListByName = (payload: getDoctorListByNameBody) => {
  return function (dispatch: Dispatch<GetDoctorListByNameActionTypes>) {
    dispatch(getDoctorListByNameLoadingAction(true));
    axios
      .post(
        `${payload.baseURL}${payload.apiPath}`,
        { searchKey: payload.searchKey },
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorListByNameLoadingAction(false));
        dispatch(
          getDoctorListByNameSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorListByNameLoadingAction(false));
        dispatch(
          getDoctorListByNameErrorAction(
            {} as GetDoctorListByNameRes | any,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.response !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorListByNameAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorListByNameActionTypes>) {
    dispatch(
      getDoctorListByNameAPIMsgAction(
        apiRes as GetDoctorListByNameRes | any,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetDoctorListByNameAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorListByNameActionTypes>) {
    dispatch(
      getDoctorListByNameAPIResClearAction(
        {} as GetDoctorListByNameRes | any,
        "",
        "",
        0
      )
    );
  };
};
