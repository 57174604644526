import { combineReducers } from "redux";
import { getQuestionListReducer } from "../CaseDetails/DoctorPatientInteraction/getQuestionListForPatient/Reducer";
import { getMeetingDetailsReducer } from "../CaseDetails/DoctorPatientInteraction/GetMeetingDetails/Reducer";
import { logOutReducer } from "../logOut/Reducer";
import { patientDropDownListReducer } from "../CaseDetails/AppointmentSelectPatient/patientListDropdown/Reducer";
import { addPatientTeamReducer } from "../PatientTeam/AddPatientTeam/Reducer";
import { getPatientTeamReducer } from "../PatientTeam/GetPatientTeam/Reducer";
import { requestBookAppointmentReducer } from "../requestAppointmentByPatient/Reducer";
import { emailOtpgenerationReducer } from "../emailOtpGeneration/Reducer";
import { emailOtpSignupReducer } from "../emailOtpSignup/Reducer";
import {
  addCCReducer,
  deleteDocumentReducer,
  getCCReducer,
  getTaskReducer,
  getCaseAppoinmentListReducer,
} from "./Reducer";
import { emailOtpAuthReducer } from "../emailOtpAuth/Reducer";
import { forgotPasswordReducer } from "../forgotPassword/Reducer";
import { getAppointmentDetailsForCaseReducer } from "../Appointment/GetAppointmentList/Reducer";
import { createAppointmentReducer } from "../Appointment/RequestAppointment/Reducer";
import { addMasterConfigReducer } from "../addMasterImportConfig/Reducer";
import { getPatientImportConfigListReducer } from "../FilePreparation/ImportConfig/GetPatientImportConfigList/Reducer";
import { addPatientImportConfigDataReducer } from "../FilePreparation/ImportConfig/AddPatientImportConfig/Reducer";
import { getUserDetailsListReducer } from "../getUserDetails/Reducer";
import { resetPasswordReducer } from "../resetPassword/Reducer";
import { setEmailPhoneReducer } from "../setEmailOrPhone/Reducer";
import { userEmailOtpgenerationReducer } from "../userProfileEmailOtpGeneration/Reducer";
import { needHelpReducer } from "../needHelp/addNeedHelp/Reducer";
import { dropdownReducer } from "../needHelp/patientList/Reducer";
import { getDemographicsDetailsByPtIdReducer } from "../DemographicsDetails/GetPatientDemographicsDetails/Reducer";
import { getGenderListReducer } from "../DemographicsDetails/GenderList/Reducer";
import { updateDemographicsDetailsByPatientIdReducer } from "../DemographicsDetails/UpdateDemographicsDetails/Reducer";
import { editPatientImportConfigDataReducer } from "../FilePreparation/ImportConfig/EditPatientImportConfig/Reducer";
import { imageUploadReducer } from "../FilePreparation/ImageUpload/Reducer";
import { addDemographicsReducer } from "../DemographicsDetails/AddDemographicsDetails/Reducer";
import { getPatientLoginDetailsReducer } from "../jwtPatientLogIn/patientLoginDetails/Reducer";
import { jwtAuthTokenReducer } from "../jwtPatientLogIn/Reducer";
import { getDoctorTeamTypeForCaseReducer } from "../AppointmentSelectDoctor/DoctorTeamTypeForCase/Reducer";
import { getDoctorListByCategoryReducer } from "../AppointmentSelectDoctor/DoctorListByCategory/Reducer";
import { getDoctorListForCaseReducer } from "../AppointmentSelectDoctor/DoctorListForCase/Reducer";
import { deleteDoctorForCaseReducer } from "../AppointmentSelectDoctor/DeleteDoctor/Reducer";
import { addDoctorForCaseReducer } from "../AppointmentSelectDoctor/AddDoctorForCase/Reducer";
import { editDoctorTeamTypeReducer } from "../AppointmentSelectDoctor/EditDoctorTeamType/Reducer";
import { getDoctorCategoryListReducer } from "../Doctor/DoctorCategoryList/Reducer";
import { getDoctorTeamTypeListReducer } from "../AppointmentSelectDoctor/DoctorTeamTypeList/Reducer";
import { addPatientDataReducer } from "../FilePreparation/AddPatientDataList/Reducer";
import { getPatientDataListReducer } from "../FilePreparation/GetPatientDataList/Reducer";
import { deletePatientDataByIdReducer } from "../FilePreparation/DeletePatientDataById/Reducer";
import { getQuestionnaireListReducer } from "../FilePreparation/Questionnaire/Reducer";
import { addCaseReducer } from "../ConsultationOption/AddCase/Reducer";
import { addPatientInsuranceReducer } from "../Insurance/AddPatientnsurance/Reducer";
import { editPatientInsuranceReducer } from "../Insurance/EditPatientInsurance/Reducer";
import { getInsuranceDetailsReducer } from "../Insurance/GetInsuranceByPatient/Reducer";
import { editCaseReducer } from "../ConsultationOption/EditCase/Reducer";
import { askDoctorQuestionReducer } from "../CaseDetails/DoctorPatientInteraction/AskQuestion/Reducer";
import { answerDoctorQuestionReducer } from "../CaseDetails/DoctorPatientInteraction/AnswerQuestion/Reducer";
import { caseDetailsReducer } from "../ConsultationOption/CaseDetails/Reducer";
import { opinionTypeReducer } from "../ConsultationOption/OpinioType/Reducer";
import { secondSecondOpinionTypeReducer } from "../ConsultationOption/SecondOpinion/Reducer";
import { consultationTypeReducer } from "../ConsultationOption/ConsultationType/Reducer";
import { responseTimeReducer } from "../ConsultationOption/ResponseTime/Reducer";
import { paymentListReducer } from "../appointmentPayment/AppointmentPaymentList/Reducer";
import { paymentStatusReducer } from "../appointmentPayment/CheckPaymentStatusById/Reducer";
import { BloodGrpReducer } from "../DemographicsDetails/BloodGroupList/Reducer";
import { LanguageListReducer } from "../DemographicsDetails/LanguageList/Reducer";
import { maritalListReducer } from "../DemographicsDetails/MaritalStatusList/Reducer";
import { relationshipListReducer } from "../DemographicsDetails/RelationShipList/Reducer";
import { sexualOrientationListReducer } from "../DemographicsDetails/SexualOrientationList/Reducer";
import { doctorDetailsReducer } from "../Doctor/doctorDetailsById/Reducer";
import { doctorListReducer } from "../Doctor/ActiveDoctorList/Reducer";
import { getDoctorSpecialityListReducer } from "../Doctor/specialist/Reducer";
import { searchDoctorReducer } from "../Doctor/findDoctor/Reducer";
import { patientListReducer } from "../dashboard/patientList/Reducer";
import { sharedPatientListReducer } from "../dashboard/sharedList/Reducer";
import { patientDeleteReducer } from "../dashboard/deletePatient/Reducer";
import { patientCaseListReducer } from "../caseList/Reducer";
import { ownerAppointmentListReducer } from "../Appointment/OwnerAppoitment/Reducer";
import { getPaymentListByOwnerReducer } from "../payment/AppointmentPaymentList/Reducer";
import { getStepsDetailsReducer } from "../FilePreparation/GetSteps/Reducer";
import { paymentApproveValueReducer } from "../payment/PaymentApprove/Reducer";
import { getPatientDetailsReducer } from "../eHealth/getPatientByName/Reducer";
import { getDoctorListByNameReducer } from "../eHealth/GetDoctorListByName/Reducer";

const Reducer = combineReducers({
  doctorValue: doctorListReducer,
  patientValue: patientListReducer,
  sharedPatientValue: sharedPatientListReducer,
  caseValue: patientCaseListReducer,
  // questionValue: getQuestionReducer,
  ccValue: getCCReducer,
  ccAddVlaue: addCCReducer,
  deleteDocumentValue: deleteDocumentReducer,
  deletePatientValues: patientDeleteReducer,
  taskValue: getTaskReducer,
  ownerAppoinmentListValue: ownerAppointmentListReducer,
  CaseAppoinmentListValue: getCaseAppoinmentListReducer,
  getMeetingDetailsRes: getMeetingDetailsReducer,
  getQuestionListRes: getQuestionListReducer,
  bookAppointmentRequest: requestBookAppointmentReducer,
  patientDropDownListRes: patientDropDownListReducer,
  logOutRes: logOutReducer,
  getPatientTeamRes: getPatientTeamReducer,
  addPatientTeamRes: addPatientTeamReducer,
  emailOtpGeneration: emailOtpgenerationReducer,
  emailOtpSignup: emailOtpSignupReducer,
  emailOtpAuth: emailOtpAuthReducer,
  // apiAuthToken: apiAuthTokenReducer,
  forgotPassword: forgotPasswordReducer,
  getAppointmentDetailsForCaseRes: getAppointmentDetailsForCaseReducer,
  createAppointmentRes: createAppointmentReducer,
  addMasterImport: addMasterConfigReducer,
  getPatientImportConfigList: getPatientImportConfigListReducer,
  addPatientImportConfigDataRes: addPatientImportConfigDataReducer,
  getAppointmentPaymentListRes: paymentListReducer,
  getExistingAppointmentList: getAppointmentDetailsForCaseReducer,
  checkPaymentStatusById: paymentStatusReducer,
  getPaymentListByOwner: getPaymentListByOwnerReducer,
  getUserDetailsList: getUserDetailsListReducer,
  resetPassword: resetPasswordReducer,
  setEmailPhone: setEmailPhoneReducer,
  userProfileEmailOtp: userEmailOtpgenerationReducer,
  opinionTypeValue: opinionTypeReducer,
  secondOpinionValue: secondSecondOpinionTypeReducer,
  consultationTypeValue: consultationTypeReducer,
  responseTimeValue: responseTimeReducer,
  addCase: addCaseReducer,
  editPatientCase: editCaseReducer,
  getPatientCaseDetails: caseDetailsReducer,
  addNeedHelp: needHelpReducer,
  dropdown: dropdownReducer,
  // categoryList: categoryListReducer,
  speciaList: getDoctorSpecialityListReducer,
  doctorList: doctorListReducer,
  doctorDetailsById: doctorDetailsReducer,
  getDemographicsDetailsByPtId: getDemographicsDetailsByPtIdReducer,
  getPatientRelationshipList: relationshipListReducer,
  getPatientBloodGroupList: BloodGrpReducer,
  getPatientGenderList: getGenderListReducer,
  getPatientLanguageList: LanguageListReducer,
  getPatientMaritalList: maritalListReducer,
  getPatientSexualOrientation: sexualOrientationListReducer,
  updateDemographicsDetails: updateDemographicsDetailsByPatientIdReducer,
  getInsuranceDetails: getInsuranceDetailsReducer,
  addPatientInsurance: addPatientInsuranceReducer,
  editPatientInsurance: editPatientInsuranceReducer,
  editPatientImportConfigDataRes: editPatientImportConfigDataReducer,
  addDemographics: addDemographicsReducer,

  imageUploading: imageUploadReducer,

  getPatientLoginDetailsRes: getPatientLoginDetailsReducer,
  apiAuthToken: jwtAuthTokenReducer,

  getDoctorTeamTypeForCaseRes: getDoctorTeamTypeForCaseReducer,
  editDoctorTeamTypeRes: editDoctorTeamTypeReducer,
  getDoctorCategoryListRes: getDoctorCategoryListReducer,
  getDoctorListByCategoryRes: getDoctorListByCategoryReducer,
  getDoctorListForCaseRes: getDoctorListForCaseReducer,
  deleteDoctorForCaseRes: deleteDoctorForCaseReducer,
  addDoctorForCaseRes: addDoctorForCaseReducer,
  doctorTeamTypeListValue: getDoctorTeamTypeListReducer,

  addPatientDataListRes: addPatientDataReducer,
  patientDataListRes: getPatientDataListReducer,
  deletePatientDataByIdRes: deletePatientDataByIdReducer,
  patientQuestionnaireListRes: getQuestionnaireListReducer,
  askDoctorQuestionRes: askDoctorQuestionReducer,
  answerDoctorQuestionRes: answerDoctorQuestionReducer,
  searchDoctor: searchDoctorReducer,
  getStepsDetailsRes: getStepsDetailsReducer,
  getPaymentApproveValue: paymentApproveValueReducer,
  getPatientDetailsRes: getPatientDetailsReducer,
  getDoctorListByNameRes: getDoctorListByNameReducer,
});

export default Reducer;
