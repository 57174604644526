import * as React from "react";
import { useEffect, useState } from "react";
import InformConsent from "./InformConsent";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, Tabs, Tab, Typography, Grid, Alert, Paper } from "@mui/material";
import { TabList } from "./TabList";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { t } from "i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

type Props = {
  filePreparationLoader?: boolean | any;
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PatientFilePreparation({
  filePreparationLoader,
}: Props) {
  const history = useHistory();
  let query = useQuery();
  const fpId = query.get("fp");
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [tabValue, setTabValue] = useState(1);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    window.scrollTo(0, 0);
    if (newValue === 0) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=demographics"}`
      );
    } else if (newValue === 1) {
      history.push(
        `${
          window.location.pathname + "?&tab=filePreparation&fp=chiefComplaint"
        }`
      );
    } else if (newValue === 2) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=ideaConcern"}`
      );
    } else if (newValue === 3) {
      history.push(
        `${
          window.location.pathname +
          "?&tab=filePreparation&fp=historyPresentComplaint"
        }`
      );
    } else if (newValue === 4) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=systemReview"}`
      );
    } else if (newValue === 5) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=pastHistory"}`
      );
    } else if (newValue === 6) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=testProcedure"}`
      );
    } else if (newValue === 7) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=medication"}`
      );
    } else if (newValue === 8) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=allergies"}`
      );
    } else if (newValue === 9) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=immunization"}`
      );
    } else if (newValue === 10) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=familyHistory"}`
      );
    } else if (newValue === 11) {
      history.push(
        `${
          window.location.pathname +
          "?&tab=filePreparation&fp=nutritionalHistory"
        }`
      );
    } else if (newValue === 12) {
      history.push(
        `${
          window.location.pathname + "?&tab=filePreparation&fp=personalHistory"
        }`
      );
    } else if (newValue === 13) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=healthHistory"}`
      );
    } else if (newValue === 14) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=riskFactor"}`
      );
    } else if (newValue === 15) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=diagnosis"}`
      );
    } else if (newValue === 16) {
      history.push(
        `${
          window.location.pathname + "?&tab=filePreparation&fp=medicalSummary"
        }`
      );
    } else if (newValue === 17) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=problemList"}`
      );
    } else if (newValue === 18) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=treatmentPlan"}`
      );
    } else if (newValue === 19) {
      history.push(
        `${window.location.pathname + "?&tab=filePreparation&fp=importData"}`
      );
    } else {
    }
  };
  const [demographicsLoader] = useState(false);
  filePreparationLoader(demographicsLoader);

  // Call Get demographics details store
  const getDemographicsDetailsByPtIdRes = useSelector(
    (state: AppState) => state.getDemographicsDetailsByPtId
  );
  // add Demographics details store
  const addDemographics = useSelector(
    (state: AppState) => state.addDemographics
  );
  // Call Relationship list store
  const getPatientRelationshipListRes = useSelector(
    (state: AppState) => state.getPatientRelationshipList
  );
  // Call Blood Group List store
  const getPatientBloodGroupListRes = useSelector(
    (state: AppState) => state.getPatientBloodGroupList
  );
  // Call Gender List store
  const getPatientGenderListRes = useSelector(
    (state: AppState) => state.getPatientGenderList
  );
  // Call Language List store
  const getPatientLanguageListRes = useSelector(
    (state: AppState) => state.getPatientLanguageList
  );
  // Call Marital Status List store
  const getPatientMaritalListRes = useSelector(
    (state: AppState) => state.getPatientMaritalList
  );
  // Call Sexual Orientation List store
  const getPatientSexualOrientationRes = useSelector(
    (state: AppState) => state.getPatientSexualOrientation
  );
  // Call Update Dempographics Details store
  const updateDemographicsDetailsRes = useSelector(
    (state: AppState) => state.updateDemographicsDetails
  );
  // Call Questionnaire List Store
  const questionnaireListRes = useSelector(
    (state: AppState) => state.patientQuestionnaireListRes
  );
  // Get Patient Data List store
  const patientDataListRes = useSelector(
    (state: AppState) => state.patientDataListRes
  );
  //Call Import Data List store
  const importedValue = useSelector((state: AppState) => state.ccValue);
  // Pass Loading value to Case Details Page(Parent Component)

  useEffect(() => {
    filePreparationLoader(
      getDemographicsDetailsByPtIdRes?.loading ||
        addDemographics?.loading ||
        updateDemographicsDetailsRes?.loading ||
        getPatientRelationshipListRes?.loading ||
        getPatientBloodGroupListRes?.loading ||
        getPatientGenderListRes?.loading ||
        getPatientLanguageListRes?.loading ||
        getPatientMaritalListRes?.loading ||
        getPatientSexualOrientationRes?.loading ||
        questionnaireListRes?.loading ||
        patientDataListRes?.loading ||
        importedValue?.loading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getDemographicsDetailsByPtIdRes,
    addDemographics,
    updateDemographicsDetailsRes,
    getPatientRelationshipListRes,
    getPatientBloodGroupListRes,
    getPatientGenderListRes,
    getPatientLanguageListRes,
    getPatientMaritalListRes,
    getPatientSexualOrientationRes,
    questionnaireListRes,
    patientDataListRes,
    importedValue,
  ]);

  useEffect(
    () => {
      if (fpId === "demographics") {
        setTabValue(0);
      } else if (fpId === "chiefComplaint") {
        setTabValue(1);
      } else if (fpId === "ideaConcern") {
        setTabValue(2);
      } else if (fpId === "historyPresentComplaint") {
        setTabValue(3);
      } else if (fpId === "systemReview") {
        setTabValue(4);
      } else if (fpId === "pastHistory") {
        setTabValue(5);
      } else if (fpId === "testProcedure") {
        setTabValue(6);
      } else if (fpId === "medication") {
        setTabValue(7);
      } else if (fpId === "allergies") {
        setTabValue(8);
      } else if (fpId === "immunization") {
        setTabValue(9);
      } else if (fpId === "familyHistory") {
        setTabValue(10);
      } else if (fpId === "nutritionalHistory") {
        setTabValue(11);
      } else if (fpId === "personalHistory") {
        setTabValue(12);
      } else if (fpId === "healthHistory") {
        setTabValue(13);
      } else if (fpId === "riskFactor") {
        setTabValue(14);
      } else if (fpId === "diagnosis") {
        setTabValue(15);
      } else if (fpId === "medicalSummary") {
        setTabValue(16);
      } else if (fpId === "problemList") {
        setTabValue(17);
      } else if (fpId === "treatmentPlan") {
        setTabValue(18);
      } else if (fpId === "importData") {
        setTabValue(19);
      } else {
        setTabValue(1);
      }
    },
    // eslint-disable-next-line
    [fpId]
  );

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {/* Vertical Tab Content */}
        <Grid item xs>
          {patientid ? (
            <>
              {TabList.map((item: any, index) => {
                return (
                  <TabPanel value={tabValue} index={index} key={index}>
                    {item.content}
                  </TabPanel>
                );
              })}
            </>
          ) : null}
        </Grid>
        {/* Vertical Tab List */}
        <Grid
          item
          sx={{
            width: "280px",
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              backgroundColor: "#DCCEC9",
              width: "280px",
              margin: "20px 0px",
            }}
          >
            {patientid ? (
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Patient Health Record"
                indicatorColor="secondary"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "#93acfa",
                    left: "0",
                  },
                }}
                sx={{
                  backgroundColor: "#dae9f5",
                  alignItems: "left",
                  textTransform: "none",
                  fontSize: "1.5rem",
                  borderRadius: "4px",
                }}
              >
                {TabList.map((item: any, index) => {
                  return (
                    <Tab
                      key={index}
                      label={t(`filePreparationTab.${item.label}`, {
                        ns: ["home"],
                      })}
                      {...a11yProps(index)}
                      sx={{
                        margin: "0px",
                        width: "280px",
                      }}
                    />
                  );
                })}
              </Tabs>
            ) : (
              <>
                <Alert>
                  <Box sx={{ maxHeight: "340px", overflow: "auto" }}>
                    <InformConsent />
                  </Box>
                </Alert>
                <Box py="5">
                  <Typography>
                    {t("addpatientpage.agreeConsent", { ns: ["home"] })}
                  </Typography>
                </Box>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
