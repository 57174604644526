import React, { useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { t } from "i18next";
import MWLoader from "../../components/MWLoader";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import MWExceptionList from "../../components/MWExceptionList";
import PageLayout from "../Layout/PageLayout";
import {
  AppointmentRequestList,
  OwnerAppointmentBody,
} from "../../redux/Appointment/OwnerAppoitment/Model";
import { appoinmentRequestListForOwner } from "../../redux/Appointment/OwnerAppoitment/API";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { ModifiedOwnerAppointmentList } from "./Model";

export default function AppointmentBooking(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const selectionTypeBody = {
    caseId: caseid,
  } as OwnerAppointmentBody;

  // appoinment details from redux store....
  const OwnerAppoinmentValue = useSelector(
    (state: AppState) => state.ownerAppoinmentListValue
  );

  const gotoCaseDetails = (patientid: any, caseid: any) => {
    history.push(`casedetails/${patientid}/${caseid}?&tab=consultationType`);
  };

  const fownerApptColumn: GridColDef[] = [
    {
      field: "VIEW CASE DETAILS",
      headerName: `${t("appointment.viewcase", { ns: ["home"] })}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            gotoCaseDetails(params.row.patientId, params.row.caseId);
          }}
          sx={{ textTransform: "none" }}
        >
          {t("appointment.view", { ns: ["home"] })}
        </Button>
      ),
    },
    {
      field: "patientCaseTitle",
      headerName: `${t("appointment.desc", { ns: ["home"] })}`,
      minWidth: 80,
      flex: 1,
    },
    {
      field: "createDate",
      headerName: `${t("appointment.createdate", { ns: ["home"] })}`,
      minWidth: 80,
    },
    {
      field: "modifiedDate",
      headerName: `${t("appointment.modifydate", { ns: ["home"] })}`,
      minWidth: 80,
    },
    {
      field: "end",
      headerName: `${t("appointment.enddate", { ns: ["home"] })}`,
      minWidth: 80,
    },
    {
      field: "status",
      headerName: `${t("appointment.status", { ns: ["home"] })}`,
      minWidth: 80,
    },
  ];

  function getAppoinmentRequestList() {
    dispatch(appoinmentRequestListForOwner(selectionTypeBody));
  }

  const [ownerAppointmentList, setOwnerAppointmentList] = useState(
    [] as ModifiedOwnerAppointmentList[]
  );

  useEffect(() => {
    dispatch(appoinmentRequestListForOwner(selectionTypeBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      OwnerAppoinmentValue?.ownerAppointmentListResponse
        ?.appointmentRequestList !== undefined
    ) {
      if (
        OwnerAppoinmentValue?.ownerAppointmentListResponse
          ?.appointmentRequestList.length > 0
      ) {
        let ownerApptListItem =
          OwnerAppoinmentValue?.ownerAppointmentListResponse?.appointmentRequestList.map(
            (element: AppointmentRequestList) =>
              ({
                id: element?.id,
                name: element?.name !== undefined ? element?.name : "",
                createDate:
                  element?.createDate !== undefined
                    ? element?.createDate !== null
                      ? dayjs(element?.createDate).format("DD-MM-YYYY")
                      : `${t("common.notSet", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                isActive:
                  element?.isActive !== undefined
                    ? element?.isActive
                      ? `${t("common.true", { ns: ["home"] })!}`
                      : `${t("common.true", { ns: ["home"] })!}`
                    : false,
                modifiedDate:
                  element?.modifiedDate !== undefined
                    ? element?.modifiedDate !== null
                      ? dayjs(element?.modifiedDate).format("DD-MM-YYYY")
                      : `${t("common.notSet", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                status:
                  element?.status !== undefined
                    ? element?.status
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                start:
                  element?.start !== undefined
                    ? element?.start
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                end:
                  element?.end !== undefined
                    ? element?.end
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                patientName:
                  element?.patientName !== undefined
                    ? element?.patientName
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                caseId: element?.caseId !== undefined ? element?.caseId : "",
                patientCaseTitle:
                  element?.patientCaseTitle !== undefined
                    ? element?.patientCaseTitle
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                patientId:
                  element?.patientId !== undefined ? element?.patientId : "",
              } as ModifiedOwnerAppointmentList)
          );
        setOwnerAppointmentList(ownerApptListItem);
      } else setOwnerAppointmentList([] as ModifiedOwnerAppointmentList[]);
    } else setOwnerAppointmentList([] as ModifiedOwnerAppointmentList[]);
  }, [OwnerAppoinmentValue]);

  const appointmentTableBody = (
    <div>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>{t("appointment.exisappo", { ns: ["home"] })!}</Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={getAppoinmentRequestList}
                >
                  {t("appointment.appolist", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {OwnerAppoinmentValue.ownerAppointmentListResponse
            ?.appointmentRequestList !== undefined &&
          OwnerAppoinmentValue?.ownerAppointmentListResponse
            ?.appointmentRequestList.length > 0 ? (
            <DataGrid
              rows={ownerAppointmentList}
              columns={fownerApptColumn}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      <br />
    </div>
  );

  return (
    <Box>
      {OwnerAppoinmentValue.loading ? <MWLoader /> : null}
      <PageLayout>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          {t("appointment.appo", { ns: ["home"] })!}
        </Typography>
        {appointmentTableBody}
      </PageLayout>
    </Box>
  );
}
