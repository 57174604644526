import {
  GetDoctorListByNameActionTypes,
  GET_DOCTOR_LIST_BY_NAME_FAIL,
  GET_DOCTOR_LIST_BY_NAME_LOADING,
  GET_DOCTOR_LIST_BY_NAME_SUCCESS,
  GetDoctorListByNameState,
  GET_DOCTOR_LIST_BY_NAME_API_MSG,
  GET_DOCTOR_LIST_BY_NAME_API_RES,
} from "./ActionTypes";
import { GetDoctorListByNameRes } from "./Model";

const initialStateUpdatePosts: GetDoctorListByNameState = {
  loading: false,
  getDoctorListByNameRes: {} as GetDoctorListByNameRes | any,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getDoctorListByNameReducer = (
  state = initialStateUpdatePosts,
  action: GetDoctorListByNameActionTypes
): GetDoctorListByNameState => {
  switch (action.type) {
    case GET_DOCTOR_LIST_BY_NAME_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DOCTOR_LIST_BY_NAME_SUCCESS:
      return {
        ...state,
        getDoctorListByNameRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_DOCTOR_LIST_BY_NAME_FAIL:
      return {
        ...state,
        getDoctorListByNameRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_LIST_BY_NAME_API_MSG:
      return {
        ...state,
        getDoctorListByNameRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_LIST_BY_NAME_API_RES:
      return {
        ...state,
        getDoctorListByNameRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
