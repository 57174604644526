import {
  Alert,
  Autocomplete,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import _ from "lodash";
import { GetPatientDetailsBody } from "../../../redux/eHealth/getPatientByName/Model";
import {
  clearGetPatientDetailsAPIRes,
  getPatientDetails,
} from "../../../redux/eHealth/getPatientByName/API";
import {
  clearGetDoctorListByNameAPIRes,
  getDoctorListByName,
} from "../../../redux/eHealth/GetDoctorListByName/API";
import baseUrl from "../../../Url";
import { t } from "i18next";

type MethodList = {
  label: string;
  value: number;
};

function HealthCareProviderDirectory() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearGetPatientDetailsAPIRes());
  }, [dispatch]);

  const methodList = [
    {
      label: "GET",
      value: 1,
    },
    {
      label: "POST",
      value: 2,
    },
  ] as MethodList[];

  const [selectedMethod, setSelectedMethod] = React.useState(methodList[0]);
  const handleMethodChange = (newValue: any) => {
    setSelectedMethod(newValue);
  };
  const [baseURL, setBaseUrl] = useState("");
  const [apiPath, setAPIPath] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [response, setResponse] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (selectedMethod.value === 1) {
      setBaseUrl("https://dummyjson.com");
      setAPIPath("/users/search?q=");
      setResponse("");
      setSearchKey("");
    } else {
      setBaseUrl(baseUrl.baseUrl!);
      setAPIPath("/patientapp/api/search-doctor/");
      setSearchKey("");
      setResponse("");
    }
  }, [selectedMethod]);

  const findResult = () => {
    if (
      !_.isEmpty(selectedMethod) &&
      baseURL !== "" &&
      apiPath !== "" &&
      searchKey !== ""
    ) {
      setErrorMsg("");
      if (selectedMethod.value === 1) {
        dispatch(
          getPatientDetails({
            method: selectedMethod?.label,
            baseURL: baseURL,
            apiPath: apiPath,
            searchKey: searchKey,
          } as GetPatientDetailsBody)
        );
      } else {
        dispatch(clearGetDoctorListByNameAPIRes());
        dispatch(
          getDoctorListByName({
            method: selectedMethod?.label,
            baseURL: baseURL,
            apiPath: apiPath,
            searchKey: searchKey,
          } as GetPatientDetailsBody)
        );
      }
    } else {
      setErrorMsg("All Fields are required");
    }
  };

  const getPatientDetailsRes = useSelector(
    (state: AppState) => state.getPatientDetailsRes
  );
  useEffect(() => {
    if (getPatientDetailsRes?.successMsg !== "") {
      setResponse(JSON.stringify(getPatientDetailsRes?.getPatientDetailsRes));
    }
  }, [getPatientDetailsRes]);

  const getDoctorListByNameRes = useSelector(
    (state: AppState) => state.getDoctorListByNameRes
  );
  useEffect(() => {
    if (getDoctorListByNameRes?.successMsg !== "") {
      setResponse(
        JSON.stringify(getDoctorListByNameRes?.getDoctorListByNameRes)
      );
    }
  }, [getDoctorListByNameRes]);
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={1}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h5">{t("swissHealth.shDetail", { ns: ["home"] })}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Autocomplete
              value={selectedMethod}
              onChange={(_event: any, newValue: any) => {
                handleMethodChange(newValue);
              }}
              id="controllable-states-demo"
              options={methodList}
              disabled={getPatientDetailsRes?.loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("swissHealth.shMthd", { ns: ["home"] })}
                  variant="outlined"
                  placeholder={t("swissHealth.shMthdPlacehldr", { ns: ["home"] })!}
                />
              )}
              disableClearable={true as any}
              getOptionLabel={(option: any) => option?.label! || ""}
              isOptionEqualToValue={(option: any, value: any) =>
                option.value === value.value
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              value={baseURL}
              onChange={(e) => {
                setBaseUrl(e.target.value);
              }}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  findResult();
                }
              }}
              disabled={getPatientDetailsRes?.loading}
              variant="outlined"
              label={t("swissHealth.shBsUrl", { ns: ["home"] })}
              placeholder={t("swissHealth.shBsUrlPlacehldr", { ns: ["home"] })!}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              value={apiPath}
              onChange={(e) => {
                setAPIPath(e.target.value);
              }}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  findResult();
                }
              }}
              disabled={getPatientDetailsRes?.loading}
              variant="outlined"
              label={t("swissHealth.shApiPath", { ns: ["home"] })}
              placeholder={t("swissHealth.shApiPathPlacehldr", { ns: ["home"] })!}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              value={searchKey}
              onChange={(e: any) => {
                setSearchKey(e.target.value);
              }}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  findResult();
                }
              }}
              disabled={getPatientDetailsRes?.loading}
              variant="outlined"
              label={t("swissHealth.shSearchKey", { ns: ["home"] })}
              placeholder={t("swissHealth.shSearchKeyPlacehldr", { ns: ["home"] })!}
              fullWidth
            />
          </Grid>
          {errorMsg !== "" && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Alert severity="error" onClose={() => setErrorMsg("")}>
                {errorMsg}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              value={response}
              disabled={getPatientDetailsRes?.loading}
              variant="outlined"
              label={t("swissHealth.shResult", { ns: ["home"] })}
              placeholder={t("swissHealth.shResultPlacehldr", { ns: ["home"] })!}
              multiline
              rows={12}
              maxRows={12}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HealthCareProviderDirectory;
