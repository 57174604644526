import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWExceptionList from "../../../../components/MWExceptionList";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  AppointmentRequestList,
  GetAppointmentDetailsForCaseBody,
} from "../../../../redux/Appointment/GetAppointmentList/Model";
import { getAppointmentDetailsForCase } from "../../../../redux/Appointment/GetAppointmentList/API";
import { ModifiedAppointmentList } from "./Model";
import { t } from "i18next";

export interface Props {
  caseId: string | undefined;
}
export default function ExistingAppointmentDetails({ caseId }: Props) {
  const dispatch = useDispatch();
  const getAppointmentDetailsForCaseRes = useSelector(
    (state: AppState) => state.getAppointmentDetailsForCaseRes
  );
  const [appointmentList, setAppointmentList] = useState(
    [] as ModifiedAppointmentList[]
  );
  useEffect(() => {
    if (
      getAppointmentDetailsForCaseRes?.getAppointmentDetailsForCaseRes
        ?.appointmentRequestList !== undefined
    ) {
      if (
        getAppointmentDetailsForCaseRes?.getAppointmentDetailsForCaseRes
          ?.appointmentRequestList.length > 0
      ) {
        let appointmentListItem =
          getAppointmentDetailsForCaseRes?.getAppointmentDetailsForCaseRes?.appointmentRequestList.map(
            (item: AppointmentRequestList) =>
              ({
                id: item?.id,
                name: item?.name,
                createDate:
                  item?.createDate !== null
                    ? dayjs(item?.createDate).format("DD-MM-YYYY")
                    : "Not Set",
                modifiedDate:
                  item?.modifiedDate !== null
                    ? dayjs(item?.modifiedDate).format("DD-MM-YYYY")
                    : "Not Set",
                status:
                  item?.status !== undefined
                    ? item?.status !== null
                      ? item?.status
                      : ""
                    : "",
                isActive:
                  item?.isActive !== undefined
                    ? item?.isActive !== null
                      ? item?.isActive === true
                        ? "True"
                        : "False"
                      : "False"
                    : "False",
              } as ModifiedAppointmentList)
          );
        setAppointmentList(appointmentListItem);
      } else setAppointmentList([] as ModifiedAppointmentList[]);
    } else setAppointmentList([] as ModifiedAppointmentList[]);
  }, [getAppointmentDetailsForCaseRes]);

  const appointmentTableColumns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("appointment.desc", { ns: ["home"] })}`,
      flex: 1
    },
    {
      field: "createDate",
      headerName: `${t("appointment.createdate", { ns: ["home"] })}`,
      minWidth: 130,
    },
    {
      field: "isActive",
      headerName: `${t("selOpPage.active", { ns: ["home"] })}`,
      minWidth: 50,
    },
    {
      field: "modifiedDate",
      headerName: `${t("appointment.modifydate", { ns: ["home"] })}`,
      minWidth: 100,
    },
    {
      field: "status",
      headerName: `${t("appointment.apptStatus", { ns: ["home"] })}`,
      minWidth: 150,
    },
  ];
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h5">
                  {t("appointment.exisappo", { ns: ["home"] })!}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={() =>
                    dispatch(
                      getAppointmentDetailsForCase({
                        caseId: caseId,
                      } as GetAppointmentDetailsForCaseBody)
                    )
                  }
                >
                  {t("appointment.appolist", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {appointmentList.length > 0 ? (
            <DataGrid
              rows={appointmentList}
              columns={appointmentTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
