import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { logOut } from "../../redux/logOut/API";
import { AppState } from "../../redux/store/Store";
import { LoadingButton } from "@mui/lab";

interface Props {
  dialogState: boolean;
  dialogChildState: boolean | any;
}

export default function MyPatientList({
  dialogState,
  dialogChildState,
}: Props) {
  const dispatch = useDispatch();

  const closeDeleteDialog = () => {
    dialogChildState(false);
  };

  const handleLogout = () => {
    dispatch(logOut());
  };

  const logoutResult = useSelector((state: AppState) => state.logOutRes);

  return (
    <Dialog open={dialogState} fullWidth>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item sx={{ marginTop: 1 }}>
                <WarningIcon />
              </Grid>
              <Grid item>
                <Typography variant="h6" fontWeight="bold">
                  {t("common.attention", { ns: ["home"] })!}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={closeDeleteDialog}
              sx={{
                color: (theme) => theme.palette.grey[800],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography fontWeight="bold">
            {t("common.logoutmsg", { ns: ["home"] })!}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <Button variant="outlined" onClick={closeDeleteDialog}>
            {t("common.no", { ns: ["home"] })!}
          </Button>
          {logoutResult?.loading ? (
            <LoadingButton loading={logoutResult?.loading} variant="contained">
              {t("common.yes", { ns: ["home"] })!}
            </LoadingButton>
          ) : (
            <Button variant="contained" onClick={handleLogout}>
              {t("common.yes", { ns: ["home"] })!}
            </Button>
          )}
        </>
      </DialogActions>
    </Dialog>
  );
}
