import { GetDoctorListByNameRes } from "./Model";
export const GET_DOCTOR_LIST_BY_NAME_LOADING =
  "GET_DOCTOR_LIST_BY_NAME_LOADING";
export const GET_DOCTOR_LIST_BY_NAME_SUCCESS =
  "GET_DOCTOR_LIST_BY_NAME_SUCCESS";
export const GET_DOCTOR_LIST_BY_NAME_FAIL = "GET_DOCTOR_LIST_BY_NAME_FAIL";
export const GET_DOCTOR_LIST_BY_NAME_API_MSG =
  "GET_DOCTOR_LIST_BY_NAME_API_MSG";
export const GET_DOCTOR_LIST_BY_NAME_API_RES =
  "GET_DOCTOR_LIST_BY_NAME_API_RES";

export interface GetDoctorListByNameState {
  getDoctorListByNameRes: GetDoctorListByNameRes | any;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListByNameLoading {
  type: typeof GET_DOCTOR_LIST_BY_NAME_LOADING;
  loading: boolean;
}
export interface GetDoctorListByNameSuccess {
  type: typeof GET_DOCTOR_LIST_BY_NAME_SUCCESS;
  payload: GetDoctorListByNameRes | any;
  successMsg: string;
}
export interface GetDoctorListByNameFail {
  type: typeof GET_DOCTOR_LIST_BY_NAME_FAIL;
  payload: GetDoctorListByNameRes | any;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListByNameAPIMsg {
  type: typeof GET_DOCTOR_LIST_BY_NAME_API_MSG;
  payload: GetDoctorListByNameRes | any;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListByNameAPIRes {
  type: typeof GET_DOCTOR_LIST_BY_NAME_API_RES;
  payload: GetDoctorListByNameRes | any;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorListByNameActionTypes =
  | GetDoctorListByNameLoading
  | GetDoctorListByNameSuccess
  | GetDoctorListByNameFail
  | GetDoctorListByNameAPIMsg
  | GetDoctorListByNameAPIRes;
