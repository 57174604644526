import axios from "axios";
import { Dispatch } from "redux";
import { GetPatientDetailsActionTypes } from "./ActionTypes";
import { GetPatientDetailsBody, GetPatientDetailsRes } from "./Model";
import {
  getPatientDetailsAPIResClearAction,
  getPatientDetailsErrorAction,
  getPatientDetailsLoadingAction,
  getPatientDetailsSuccessAction,
  getPatientDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "../../../components/Utility";

let apiRes = {} as GetPatientDetailsRes | any;
export const getPatientDetails = (searchQuery: GetPatientDetailsBody) => {
  return function (dispatch: Dispatch<GetPatientDetailsActionTypes>) {
    dispatch(getPatientDetailsLoadingAction(true));
    axios
      .get(
        `${searchQuery.baseURL}${searchQuery.apiPath}${searchQuery.searchKey}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientDetailsLoadingAction(false));
        dispatch(
          getPatientDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getPatientDetailsLoadingAction(false));
        dispatch(
          getPatientDetailsErrorAction(
            {} as GetPatientDetailsRes | any,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.response !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetPatientDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientDetailsActionTypes>) {
    dispatch(
      getPatientDetailsAPIMsgAction(
        apiRes as GetPatientDetailsRes | any,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetPatientDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientDetailsActionTypes>) {
    dispatch(
      getPatientDetailsAPIResClearAction(
        {} as GetPatientDetailsRes | any,
        "",
        "",
        0
      )
    );
  };
};
