import {
  GET_PATIENT_DETAILS_FAIL,
  GET_PATIENT_DETAILS_LOADING,
  GET_PATIENT_DETAILS_SUCCESS,
  GET_PATIENT_DETAILS_API_MSG,
  GET_PATIENT_DETAILS_API_RES,
  GetPatientDetailsActionTypes,
} from "./ActionTypes";
import { GetPatientDetailsRes } from "./Model";

export const getPatientDetailsLoadingAction = (
  loading: boolean
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_LOADING,
    loading: loading,
  };
};

export const getPatientDetailsSuccessAction = (
  getPatientDetailsResponse: GetPatientDetailsRes | any,
  successMsg: string
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_SUCCESS,
    payload: getPatientDetailsResponse,
    successMsg: successMsg,
  };
};

export const getPatientDetailsErrorAction = (
  getPatientDetailsResponse: GetPatientDetailsRes | any,
  errMsg: string,
  status: number
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_FAIL,
    payload: getPatientDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientDetailsAPIMsgAction = (
  getPatientDetailsResponse: GetPatientDetailsRes | any,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_API_MSG,
    payload: getPatientDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientDetailsAPIResClearAction = (
  getPatientDetailsResponse: GetPatientDetailsRes | any,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_DETAILS_API_RES,
    payload: getPatientDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
