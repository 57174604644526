import { Box, Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import SyncIcon from "@mui/icons-material/Sync";

function ConsistentTime() {
  const [time, setTime] = useState(dayjs());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(dayjs());
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6" fontWeight={"bold"}>
                {t("swissHealth.shSystemTym", { ns: ["home"] })}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                startIcon={<SyncIcon />}
              >
                {t("swissHealth.shSncBT", { ns: ["home"] })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5" fontWeight={"bold"}>
              {dayjs(time).format("MMM DD, YYYY Z ")}{Intl.DateTimeFormat().resolvedOptions().timeZone}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h1" fontWeight={"bold"}>
                {dayjs(time).format("HH:mm:ss")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ConsistentTime;
