import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "../../../components/MWDialogTransition";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedInsuranceList } from "../Model";
import MWUploader from "../../../components/MWUploader";
import { AppState } from "../../../redux/store/Store";
import dayjs, { Dayjs } from "dayjs";
import { AddPatientInsuranceBody } from "../../../redux/Insurance/AddPatientnsurance/Model";
import MWLoader from "../../../components/MWLoader";
import { clearImageUploadAPIRes } from "../../../redux/FilePreparation/ImageUpload/API";
import {
  addPatientInsurance,
  clearAddPatientInsuranceAPIRes,
} from "../../../redux/Insurance/AddPatientnsurance/API";
import { getInsuranceList } from "../../../redux/Insurance/GetInsuranceByPatient/API";
import {
  clearEditPatientInsuranceAPIRes,
  editPatientInsurance,
} from "../../../redux/Insurance/EditPatientInsurance/API";
import { EditPatientInsuranceBody } from "../../../redux/Insurance/EditPatientInsurance/Model";
import { useParams } from "react-router-dom";
import { GetPatientBody } from "../../../redux/Insurance/GetInsuranceByPatient/Model";
export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: ModifiedInsuranceList | any;
  selectedItemUpdateState: any;
}
export default function UpdateInsurance({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);
  const { patientid } = useParams() as {
    patientid: string;
  };
  const theme = useTheme();
  const INSURANCE_REGEX = /^[a-zA-Z0-9]+$/;

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] =
    useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setInsuranceNumber("");
    dispatch(clearImageUploadAPIRes());
    setImageId("");
    setSelectedDate(dayjs(new Date()));
    setSelectedEndDate(dayjs(new Date()));
    selectedItemUpdateState({} as any);
    dispatch(clearAddPatientInsuranceAPIRes());
    setAddDrInsuranceMsg("");
    dispatch(clearEditPatientInsuranceAPIRes());
    setEditInsuranceMsg("");
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  const [insuranceNumber, setInsuranceNumber] = useState("");
  const [insuranceNumberErrorMsg, setInsuranceNumberErrorMsg] = useState("");

  const [coverageAmount, setCoverageAmount] = useState(0);
  const [coverageAmountErrorMsg, setCoverageAmountErrorMsg] = useState("");

  // from Date
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  // to Date
  const [endDate, setSelectedEndDate] = React.useState<Dayjs | null>(null);
  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedEndDate(date);
  };
  const [startEndDateError, setStartEndDateError] = useState("");
  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  const [insuranceDetails, setInsuranceDetails] = useState("");

  useEffect(() => {
    selectedItem?.insuranceNumber !== undefined
      ? setInsuranceNumber(selectedItem?.insuranceNumber)
      : setInsuranceNumber("");
    selectedItem?.amount !== undefined
      ? setCoverageAmount(selectedItem?.amount)
      : setCoverageAmount(0);

    if (selectedItem?.fromDate !== undefined) {
      if (selectedItem?.fromDate !== null) {
        let year = selectedItem?.fromDate.split("-")[2];
        let month = selectedItem?.fromDate.split("-")[1];
        let day = selectedItem?.fromDate.split("-")[0];
        let date = year + "-" + month + "-" + day;
        setSelectedDate(dayjs(date));
      }
    }
    if (selectedItem?.fromDate === undefined) {
      setSelectedDate(dayjs(new Date()));
    } else if (selectedItem?.fromDate === null) {
      setSelectedDate(dayjs(new Date()));
    }

    if (selectedItem?.toDate !== undefined) {
      if (selectedItem?.toDate !== null) {
        let year = selectedItem?.toDate.split("-")[2];
        let month = selectedItem?.toDate.split("-")[1];
        let day = selectedItem?.toDate.split("-")[0];
        let date = year + "-" + month + "-" + day;
        setSelectedEndDate(dayjs(date));
      }
    }
    if (selectedItem?.toDate === undefined) {
      setSelectedEndDate(null);
    } else if (selectedItem?.toDate === null) {
      setSelectedEndDate(null);
    }

    selectedItem?.description !== undefined
      ? setInsuranceDetails(selectedItem?.description)
      : setInsuranceDetails("");

    selectedItem?.supportingDocument !== undefined
      ? selectedItem?.supportingDocument !== ""
        ? setImageInfo(selectedItem?.supportingDocument)
        : setImageInfo("")
      : setImageInfo("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const addInsuranceBody = {
    patientId: patientid,
    insuranceNumber: insuranceNumber,
    amount: coverageAmount,
    fromDate:
      selectedDate !== null
        ? dayjs(selectedDate).format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
    toDate:
      endDate !== null ? dayjs(endDate).format("YYYY-MM-DDTHH:mm:ssZ") : null,
    description: insuranceDetails,
  } as AddPatientInsuranceBody;

  const submitInsurance = () => {
    if (insuranceNumber === "" && coverageAmount === 0) {
      setInsuranceNumberErrorMsg(
        `${t("insurancePage.plsEnterInsNum", { ns: ["home"] })!}`
      );
      setCoverageAmountErrorMsg(
        `${t("insurancePage.enterInsCov", { ns: ["home"] })!}`
      );
    } else if (
      new Date(String(selectedDate)) > new Date(String(endDate)) ||
      new Date(String(endDate)) < new Date(String(selectedDate))
    ) {
      setStartEndDateError(`${t("common.datevalid", { ns: ["home"] })!}`);
    }
     else if (insuranceNumber === "" && coverageAmount !== 0) {
      setInsuranceNumberErrorMsg(
        `${t("insurancePage.plsEnterInsNum", { ns: ["home"] })!}`
      );
      setCoverageAmountErrorMsg("");
    } else if (insuranceNumber !== "" && coverageAmount === 0) {
      setInsuranceNumberErrorMsg("");
      setCoverageAmountErrorMsg(
        `${t("insurancePage.enterInsCov", { ns: ["home"] })!}`
      );
    } else {
      setInsuranceNumberErrorMsg("");
      setCoverageAmountErrorMsg("");
      if (selectedItem?.id === undefined) {
        // Add Dr. Insurance Details
        if (imageId === undefined) {
          dispatch(
            addPatientInsurance(
              Object.assign(addInsuranceBody, {
                supportdocId: imageId !== "" ? imageId : null,
              } as AddPatientInsuranceBody)
            )
          );
        } else {
          dispatch(
            addPatientInsurance(
              Object.assign(addInsuranceBody, {
                supportdocId: imageId !== "" ? imageId! : null,
              } as AddPatientInsuranceBody)
            )
          );
        }
      } else {
        // Edit Dr. Insurance Details
        if (imageId === undefined) {
          // When user does not upload image on add work experience
          dispatch(
            editPatientInsurance(
              Object.assign(addInsuranceBody, {
                insuranceDetailId: Number(selectedItem?.id!),
                supportdocId: imageId !== "" ? imageId : null,
              } as EditPatientInsuranceBody)
            )
          );
        } else {
          dispatch(
            editPatientInsurance(
              Object.assign(addInsuranceBody, {
                insuranceDetailId: Number(selectedItem?.id!),
                supportdocId: imageId !== "" ? imageId : null,
              } as EditPatientInsuranceBody)
            )
          );
        }
      }
    }
  };

  const addPatientInsuranceRes = useSelector(
    (state: AppState) => state.addPatientInsurance
  );
  const [addDrInsuranceMsg, setAddDrInsuranceMsg] = useState("");
  const [addDrInsuranceMsgType, setAddDrInsuranceMsgType] =
    useState<AlertColor>("success");

  const getInsuranceBody = {
    patientId: patientid,
  } as GetPatientBody;

  useEffect(() => {
    if (addPatientInsuranceRes?.successMsg !== "") {
      setAddDrInsuranceMsgType("success");
      setAddDrInsuranceMsg(addPatientInsuranceRes?.successMsg);
      dispatch(getInsuranceList(getInsuranceBody));
      closeDialog();
    }
    if (addPatientInsuranceRes?.errorMsg !== "") {
      setAddDrInsuranceMsgType("error");
      setAddDrInsuranceMsg(addPatientInsuranceRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientInsuranceRes]);

  const editPatientInsuranceRes = useSelector(
    (state: AppState) => state.editPatientInsurance
  );
  const [editInsuranceMsg, setEditInsuranceMsg] = useState("");
  const [editInsuranceMsgType, setEditInsuranceMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (editPatientInsuranceRes?.successMsg !== "") {
      setEditInsuranceMsgType("success");
      setEditInsuranceMsg(editPatientInsuranceRes?.successMsg);
      dispatch(getInsuranceList(getInsuranceBody));
      closeDialog();
    }
    if (editPatientInsuranceRes?.errorMsg !== "") {
      setEditInsuranceMsgType("error");
      setEditInsuranceMsg(editPatientInsuranceRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPatientInsuranceRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-insuranceNumber"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {addPatientInsuranceRes?.loading || editPatientInsuranceRes?.loading ? (
        <MWLoader />
      ) : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            {selectedItem?.id ? (
              <Typography variant="h5">
                {t("insurancePage.editIns", {
                  ns: ["home"],
                })}
              </Typography>
            ) : (
              <Typography variant="h5">
                {t("insurancePage.insAdd", {
                  ns: ["home"],
                })}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography style={{ marginBottom: "5px" }}>
                {t("insurancePage.enterInsNum", { ns: ["home"] })}
              </Typography>
              <TextField
                value={insuranceNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (
                    event.target.value !== "" &&
                    !INSURANCE_REGEX.test(event.target.value)
                  ) {
                    return;
                  }
                  setInsuranceNumber(event.target.value);
                }}
                label=""
                placeholder={
                  t("insurancePage.plsEnterInsNum", { ns: ["home"] })!
                }
                type="text"
                fullWidth
                size="small"
                inputProps={{ maxLength: 12, minLength: 12 }}
                error={
                  insuranceNumber !== "" && insuranceNumber.length !== 12 ? true : false
                }
              />
              {insuranceNumberErrorMsg !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {insuranceNumberErrorMsg}
                </Typography>
              ) : null}
              <Typography style={{ marginBottom: "5px" }}>
                {t("insurancePage.insCovAmt", { ns: ["home"] })}
              </Typography>
              <TextField
                value={coverageAmount}
                onChange={(e) => setCoverageAmount(Number(e.target.value))}
                label=""
                placeholder={t("insurancePage.enterInsCov", { ns: ["home"] })!}
                type="number"
                fullWidth
                size="small"
              />
              {coverageAmountErrorMsg !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {coverageAmountErrorMsg}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="patient-image"
                criteria={t("allergyPage.uploadFile", { ns: ["home"] })!}
                updatedDataState={updateDataStateFromChild}
                title={t("preCompPage.suptDocu", { ns: ["home"] })!}
                visibility={false}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>
                {t("insurancePage.insStartDate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>
                {t("insurancePage.insEndDate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  onChange={handleToDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                  // maxDate={isEdit ? dayjs(new Date()) : undefined}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography style={{ marginBottom: "5px" }}>
                {t("insurancePage.insDetail", { ns: ["home"] })}
              </Typography>
              <TextField
                value={insuranceDetails}
                onChange={(e) => setInsuranceDetails(e.target.value)}
                label=""
                placeholder={
                  t("insurancePage.enterInsDetail", { ns: ["home"] })!
                }
                type="text"
                fullWidth
                size="small"
                multiline
                minRows={2}
                maxRows={4}
              />
            </Grid>
          </Grid>
          {startEndDateError !== "" ? (
          <Typography
            style={{
              marginBottom: "5px",
              color: "#d32f2f",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            {startEndDateError}
          </Typography>
        ) : null}
        </Stack>
        {addDrInsuranceMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addDrInsuranceMsgType}
              onClose={() => {
                dispatch(clearAddPatientInsuranceAPIRes());
                setAddDrInsuranceMsg("");
              }}
            >
              {addDrInsuranceMsg}
            </Alert>
          </Box>
        ) : null}
        {editInsuranceMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editInsuranceMsgType}
              onClose={() => {
                dispatch(clearEditPatientInsuranceAPIRes());
                setEditInsuranceMsg("");
              }}
            >
              {editInsuranceMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => closeDialog()}
              sx={{ textTransform: "none" }}
            >
              {t("common.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={
                addPatientInsuranceRes?.loading ||
                editPatientInsuranceRes?.loading
              }
              onClick={() => submitInsurance()}
              sx={{ textTransform: "none" }}
            >
              {t("needhelppage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
