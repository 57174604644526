import { GetPatientDetailsRes } from "./Model";
export const GET_PATIENT_DETAILS_LOADING = "GET_PATIENT_DETAILS_LOADING";
export const GET_PATIENT_DETAILS_SUCCESS = "GET_PATIENT_DETAILS_SUCCESS";
export const GET_PATIENT_DETAILS_FAIL = "GET_PATIENT_DETAILS_FAIL";
export const GET_PATIENT_DETAILS_API_MSG = "GET_PATIENT_DETAILS_API_MSG";
export const GET_PATIENT_DETAILS_API_RES = "GET_PATIENT_DETAILS_API_RES";

export interface GetPatientDetailsState {
  getPatientDetailsRes: GetPatientDetailsRes | any;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsLoading {
  type: typeof GET_PATIENT_DETAILS_LOADING;
  loading: boolean;
}
export interface GetPatientDetailsSuccess {
  type: typeof GET_PATIENT_DETAILS_SUCCESS;
  payload: GetPatientDetailsRes | any;
  successMsg: string;
}
export interface GetPatientDetailsFail {
  type: typeof GET_PATIENT_DETAILS_FAIL;
  payload: GetPatientDetailsRes | any;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsAPIMsg {
  type: typeof GET_PATIENT_DETAILS_API_MSG;
  payload: GetPatientDetailsRes | any;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientDetailsAPIRes {
  type: typeof GET_PATIENT_DETAILS_API_RES;
  payload: GetPatientDetailsRes | any;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientDetailsActionTypes =
  | GetPatientDetailsLoading
  | GetPatientDetailsSuccess
  | GetPatientDetailsFail
  | GetPatientDetailsAPIMsg
  | GetPatientDetailsAPIRes;
