import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { withStyles } from "tss-react/mui";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PaymentIcon from "@mui/icons-material/Payment";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AppointmentSelectPatient from "./AppointmentSelectPatient";
import AppointmentSelectDoctor from "./SelectDoctor";
import AppointmentBooking from "./BookAppointment";
import AppointmentPayment from "./PaymentConfirm";
import AddPatientTeam from "./AddPatientTeam";
import FinalOpinion from "./FinalOpinion";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { CaseList } from "../../redux/interfaces/Model";
import * as _ from "lodash";
import { t } from "i18next";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popover,
  Snackbar,
  Step,
  StepButton,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ConsultationOption from "./ConsultationOption";
import MWPageTitle from "../../components/MWPageTitle";
import DoctorPatientInteraction from "./DoctorPatientInteraction";
import { deletePatientCaseAPI } from "../../redux/FilePreparation/ApiCall";
import PageLayout from "../Layout/PageLayout";
import PatientFilePreparation from "./FilePreparation/index";
import { patientCaseList } from "../../redux/caseList/API";
import { CaseListBody } from "../../redux/caseList/Model";
import { getStepsDetails } from "../../redux/FilePreparation/GetSteps/API";
import { SecondOpinionUi } from "../../redux/FilePreparation/GetSteps/Model";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WorkIcon from "@mui/icons-material/Work";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import CommentIcon from "@mui/icons-material/Comment";

const ColorlibConnector = withStyles(
  StepConnector,
  (_theme, _params, classes) => ({
    alternativeLabel: {
      top: 22,
    },
    completed: {
      [`& .${classes.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(101, 116, 196) 0%,rgb(121,142,207) 50%,rgb(147,172,250) 100%)",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eeeeee",
      borderRadius: 1,
    },
  })
);

const useColorlibStepIconStyles = makeStyles()({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg,rgb(101, 116, 196) 0%,rgb(147,172,250) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg,rgb(101, 116, 196) 60%,rgb(121,142,207) 100%)",
  },
});
function ColorlibStepIcon(props: StepIconProps) {
  const { classes, cx } = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonIcon />,
    2: <AssignmentIcon />,
    3: <WorkIcon />,
    4: <AddBoxIcon />,
    5: <CoPresentIcon />,
    6: <PaymentIcon />,
    7: <GroupAddIcon />,
    8: <EmojiPeopleIcon />,
    9: <CommentIcon />,
  };
  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function FilePreparation() {
  let query = useQuery();
  const tabId = query.get("tab");
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { caseid } = useParams() as {
    caseid: string;
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [deleteCaseErrorToastActive, setDeleteCaseErrorToastActive] =
    useState(false);
  const [deleteCaseToastMessage] = useState("");
  const handleDeleteCaseToastActive = useCallback(
    () =>
      setDeleteCaseErrorToastActive(
        (deleteCaseErrorToastActive) => !deleteCaseErrorToastActive
      ),
    []
  );
  const dispatch = useDispatch();
  const toastMarkup = deleteCaseErrorToastActive ? (
    <Snackbar
      open={deleteCaseErrorToastActive}
      autoHideDuration={6000}
      onClose={handleDeleteCaseToastActive}
    >
      <Alert
        onClose={handleDeleteCaseToastActive}
        severity="error"
        sx={{ width: "100%" }}
      >
        {deleteCaseToastMessage}
      </Alert>
    </Snackbar>
  ) : null;
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([] as string[]);
  const [stepDetails, setStepDetails] = useState([] as SecondOpinionUi[]);

  // Next button hit
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep + 1 === 1) {
      history.push(`${window.location.pathname + "?&tab=consultationType"}`);
    } else if (activeStep + 1 === 2) {
      history.push(
        `${
          window.location.pathname + "?&tab=filePreparation&fp=chiefComplaint"
        }`
      );
    } else if (activeStep + 1 === 3) {
      history.push(`${window.location.pathname + "?&tab=selectDoctor"}`);
    } else if (activeStep + 1 === 4) {
      history.push(`${window.location.pathname + "?&tab=bookAppointments"}`);
    } else if (activeStep + 1 === 5) {
      history.push(`${window.location.pathname + "?&tab=payment"}`);
    } else if (activeStep + 1 === 6) {
      history.push(`${window.location.pathname + "?&tab=patientTeam"}`);
    } else if (activeStep + 1 === 7) {
      history.push(
        `${window.location.pathname + "?&tab=doctorpatientinteraction"}`
      );
    } else if (activeStep + 1 === 8) {
      history.push(`${window.location.pathname + "?&tab=secondopinion"}`);
    } else if (tabId === "secondopinion") {
      history.push(`${window.location.pathname + "?&tab=consultationType"}`);
    }
  };

  // Back button hit
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep - 1 === 7) {
      history.push(
        `${window.location.pathname + "?&tab=doctorpatientinteraction"}`
      );
    } else if (activeStep - 1 === 6) {
      history.push(`${window.location.pathname + "?&tab=patientTeam"}`);
    } else if (activeStep - 1 === 5) {
      history.push(`${window.location.pathname + "?&tab=payment"}`);
    } else if (activeStep - 1 === 4) {
      history.push(`${window.location.pathname + "?&tab=bookAppointments"}`);
    } else if (activeStep - 1 === 3) {
      history.push(`${window.location.pathname + "?&tab=selectDoctor"}`);
    } else if (activeStep - 1 === 2) {
      history.push(
        `${
          window.location.pathname + "?&tab=filePreparation&fp=chiefComplaint"
        }`
      );
    } else if (activeStep - 1 === 1) {
      history.push(`${window.location.pathname + "?&tab=consultationType"}`);
    } else if (activeStep - 1 === 0) {
      history.push(`${window.location.pathname + "?&tab=selectPatient"}`);
    }
  };

  // Stepper icon hit
  const handleStep = (step: number) => () => {
    setActiveStep(step);
    if (step === 0) {
      history.push(`${window.location.pathname + "?&tab=selectPatient"}`);
    } else if (step === 1) {
      history.push(`${window.location.pathname + "?&tab=consultationType"}`);
    } else if (step === 2) {
      history.push(
        `${
          window.location.pathname + "?&tab=filePreparation&fp=chiefComplaint"
        }`
      );
    } else if (step === 3) {
      history.push(`${window.location.pathname + "?&tab=selectDoctor"}`);
    } else if (step === 4) {
      history.push(`${window.location.pathname + "?&tab=bookAppointments"}`);
    } else if (step === 5) {
      history.push(`${window.location.pathname + "?&tab=payment"}`);
    } else if (step === 6) {
      history.push(`${window.location.pathname + "?&tab=patientTeam"}`);
    } else if (step === 7) {
      history.push(
        `${window.location.pathname + "?&tab=doctorpatientinteraction"}`
      );
    } else if (step === 8) {
      history.push(`${window.location.pathname + "?&tab=secondopinion"}`);
    }
  };

  useEffect(() => {
    if (tabId === "selectPatient") {
      setActiveStep(0);
    } else if (tabId === "consultationType") {
      setActiveStep(1);
    } else if (tabId === "filePreparation") {
      setActiveStep(2);
    } else if (tabId === "selectDoctor") {
      setActiveStep(3);
    } else if (tabId === "bookAppointments") {
      setActiveStep(4);
    } else if (tabId === "payment") {
      setActiveStep(5);
    } else if (tabId === "patientTeam") {
      setActiveStep(6);
    } else if (tabId === "doctorpatientinteraction") {
      setActiveStep(7);
    } else if (tabId === "secondopinion") {
      setActiveStep(8);
    } else {
      setActiveStep(0);
    }
    // eslint-disable-next-line
  }, [tabId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(patientCaseList({ patientId: patientid } as CaseListBody));
    dispatch(getStepsDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getStepsDetailsResponse = useSelector(
    (state: AppState) => state.getStepsDetailsRes
  );

  useEffect(() => {
    if (
      getStepsDetailsResponse?.getStepsDetailsRes?.SecondOpinionUi !== undefined
    ) {
      if (
        getStepsDetailsResponse?.getStepsDetailsRes?.SecondOpinionUi.length > 0
      ) {
        let stepItem =
          getStepsDetailsResponse?.getStepsDetailsRes?.SecondOpinionUi.map(
            (item) => item.displayValue
          );
        setSteps(stepItem);
        setStepDetails(
          getStepsDetailsResponse?.getStepsDetailsRes?.SecondOpinionUi
        );
      } else {
        setSteps([] as string[]);
        setStepDetails([] as SecondOpinionUi[]);
      }
    } else {
      setSteps([] as string[]);
      setStepDetails([] as SecondOpinionUi[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStepsDetailsResponse]);

  const filePreparationLoaderUpdatedState = (_passedVal: boolean) => {
    // setFilePreparationLoader(passedVal);
  };

  const getStepContent = (stepper: number) => {
    switch (stepDetails[stepper].name) {
      case "selectPatient":
        return <AppointmentSelectPatient />;
      case "consultationType":
        return <ConsultationOption />;
      case "filePreparation":
        return (
          <PatientFilePreparation
            filePreparationLoader={filePreparationLoaderUpdatedState}
          />
        );
      case "selectDoctor":
        return <AppointmentSelectDoctor />;
      case "bookAppointments":
        return <AppointmentBooking />;
      case "payment":
        return <AppointmentPayment />;
      case "patientTeam":
        return <AddPatientTeam />;
      case "doctorpatientinteraction":
        return <DoctorPatientInteraction />;
      case "secondopinion":
        return <FinalOpinion />;
      default:
        return "Unknown step";
    }
  };

  // Call tassk Store
  const PatientListFromAPI = useSelector((state: AppState) => state.caseValue);
  const caseDetails: CaseList = _.filter(
    PatientListFromAPI?.caseListResponse?.patientCaseList,
    ["caseId", Number(caseid)]
  )[0];
  const caseHistoryName = useSelector(
    (state: AppState) => state.caseValue?.caseListResponse?.patientName
  );
  const history = useHistory();
  // Delete body....
  const deletePastHistoryBody = {
    patientCaseId: Number(caseid),
    isActive: false,
  };
  const deleteCase = () => {
    setAnchorEl(null);
    dispatch(deletePatientCaseAPI(deletePastHistoryBody));
    history.push(`/casehistory/${patientid}`);
  };
  const pageBody = (
    <Box>
      {steps.length > 0 ? (
        <Box my={3}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: "#DAE9F5",
              position: "sticky",
              top: "64px",
              zIndex: 2,
            }}
          >
            <Stepper
              alternativeLabel
              nonLinear
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepButton onClick={handleStep(index)}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {label}
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </Paper>
          <>
            {steps.length > 0 ? (
              activeStep !== steps.length ? (
                <>{getStepContent(activeStep)}</>
              ) : null
            ) : null}
          </>
          {steps.length > 0 ? (
            activeStep !== steps.length ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={1}
                paddingTop={2}
              >
                <Grid item>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    {t("appointment.back", { ns: ["home"] })}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1
                      ? `${t("common.close", { ns: ["home"] })}`
                      : `${t("appointment.next", { ns: ["home"] })}`}
                  </Button>
                </Grid>
              </Grid>
            ) : null
          ) : null}
        </Box>
      ) : null}
    </Box>
  );

  return (
    <PageLayout>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        // sx={{
        //   position: "sticky",
        //   top: "80px",
        //   zIndex: 1,
        // }}
      >
        <Grid item>
          <MWPageTitle
            backButton={true}
            title={`${
              caseDetails === undefined
                ? `${
                    caseHistoryName === undefined
                      ? ""
                      : caseHistoryName +
                        ` ${t("common.ptdetails", { ns: ["home"] })}`
                  }`
                : `${
                    caseHistoryName === undefined
                      ? ""
                      : caseHistoryName +
                        ` ${t("common.ptdetails", { ns: ["home"] })}`
                  }`
            }`}
            enableSubtitle={true}
            subtitle={`${
              caseDetails === undefined ? "" : caseDetails.whyWant2ndOpinion
            }`}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            mt={1}
            spacing={1}
          >
            <Grid item>
              <IconButton onClick={handleClick}>
                <SettingsOutlinedIcon sx={{ color: "#6faeff" }} />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => deleteCase()}>
                      <ListItemText>
                        <Typography
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          {t("filePrepPage.delCase", { ns: ["home"] })}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <>
        {pageBody}
        {toastMarkup}
      </>
    </PageLayout>
  );
}
