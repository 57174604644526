import React from "react";

export default function SecureApplication() {
  return (
    <React.Fragment>
      <iframe
        src="https://profiles.ihe.net/ITI/TF/Volume1/ch-9.html#9.1.1.2"
        title="Secure Application"
        style={{ width: "calc(100vw - 22vw)", height: "calc(100vh - 34vh" }}
      ></iframe>
    </React.Fragment>
  );
}
