import React from "react";
import PageLayout from "../Layout/PageLayout";
import MWPageTitle from "../../components/MWPageTitle";
import { t } from "i18next";
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import ConsistentTime from "./ConsistentTime";
import SecureApplication from "./SecureApplication";
import HealthCareProviderDirectory from "./HealthCareProviderDirectory";
import { useDispatch } from "react-redux";
import { clearGetPatientDetailsAPIRes } from "../../redux/eHealth/getPatientByName/API";
import { clearGetDoctorListByNameAPIRes } from "../../redux/eHealth/GetDoctorListByName/API";

type ActorList = {
  label: string;
  value: number;
};
export default function EHealth() {
  const dispatch = useDispatch();
  const actorList = [
    {
      label: `${t("swissHealth.shCT", {
        ns: ["home"],
      })}`,
      value: 1,
    },
    {
      label: `${t("swissHealth.shSA", {
        ns: ["home"],
      })}`,
      value: 2,
    },
    {
      label: `${t("swissHealth.shHPD", {
        ns: ["home"],
      })}`,
      value: 3,
    },
  ] as ActorList[];
  const [selectedActor, setSelectedActor] = React.useState(actorList[0]);
  const handleActorChange = (newValue: any) => {
    setSelectedActor(newValue);
    if (newValue.value === 3) {
      dispatch(clearGetPatientDetailsAPIRes());
      dispatch(clearGetDoctorListByNameAPIRes());
    }
  };
  return (
    <PageLayout>
      <MWPageTitle title={t("menu.eHealth", { ns: ["home"] })!} />
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Autocomplete
                  value={selectedActor}
                  onChange={(_event: any, newValue: any) => {
                    handleActorChange(newValue);
                  }}
                  id="controllable-states-demo"
                  options={actorList}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("swissHealth.shSelectActor", { ns: ["home"] })}
                      variant="outlined"
                      placeholder={t("swissHealth.shChoose", { ns: ["home"] })!}
                    />
                  )}
                  disableClearable={true as any}
                  getOptionLabel={(option: any) => option?.label! || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.value === value.value
                  }
                />
              </Grid>
            </Grid>
          }
        />
        <CardContent sx={{ height: "calc(100vh-10vh)" }}>
          {selectedActor.value === 1 ? (
            <ConsistentTime />
          ) : selectedActor.value === 2 ? (
            <SecureApplication />
          ) : (
            selectedActor.value === 3 && <HealthCareProviderDirectory />
          )}
        </CardContent>
      </Card>
    </PageLayout>
  );
}
