import React from "react";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PeopleIcon from "@mui/icons-material/People";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

export const DrawerList = [
  {
    name: "patient",
    path: "/demographicdetails",
    icon: <PersonAddAltIcon />,
  },
  {
    name: "mypatient",
    path: "/dashboard",
    icon: <PeopleIcon />,
  },
  {
    name: "appoin",
    path: "/appoinmentDetails",
    icon: <BookOnlineIcon />,
  },
  {
    name: "finddoctor",
    path: "/doctor",
    icon: <PersonSearchIcon />,
  },
  {
    name: "payment",
    path: "/payment",
    icon: <PaymentIcon />,
  },
  {
    name: "eHealth",
    path: "/e-health",
    icon: <HealthAndSafetyIcon />,
  },
  {
    name: "profile",
    path: "/userprofile",
    icon: (
      <AccountCircleIcon />
    ),
  },
  {
    name: "logout",
    path: "/dashboard",
    icon: <LogoutIcon />,
  },
];
