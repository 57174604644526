import {
  GET_DOCTOR_LIST_BY_NAME_FAIL,
  GET_DOCTOR_LIST_BY_NAME_LOADING,
  GET_DOCTOR_LIST_BY_NAME_SUCCESS,
  GET_DOCTOR_LIST_BY_NAME_API_MSG,
  GET_DOCTOR_LIST_BY_NAME_API_RES,
  GetDoctorListByNameActionTypes,
} from "./ActionTypes";
import { GetDoctorListByNameRes } from "./Model";

export const getDoctorListByNameLoadingAction = (
  loading: boolean
): GetDoctorListByNameActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_NAME_LOADING,
    loading: loading,
  };
};

export const getDoctorListByNameSuccessAction = (
  getDoctorListByNameResponse: GetDoctorListByNameRes | any,
  successMsg: string
): GetDoctorListByNameActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_NAME_SUCCESS,
    payload: getDoctorListByNameResponse,
    successMsg: successMsg,
  };
};

export const getDoctorListByNameErrorAction = (
  getDoctorListByNameResponse: GetDoctorListByNameRes | any,
  errMsg: string,
  status: number
): GetDoctorListByNameActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_NAME_FAIL,
    payload: getDoctorListByNameResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorListByNameAPIMsgAction = (
  getDoctorListByNameResponse: GetDoctorListByNameRes | any,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorListByNameActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_NAME_API_MSG,
    payload: getDoctorListByNameResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorListByNameAPIResClearAction = (
  getDoctorListByNameResponse: GetDoctorListByNameRes | any,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorListByNameActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_NAME_API_RES,
    payload: getDoctorListByNameResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
